"use client";

import dynamic from "next/dynamic";
import { useTranslation } from "@/i18n/client";
import { useEffect, useRef, useState } from "react";
import styles from "@/assets/css/pages/font.module.css";
import { getStoreReviews } from "@/lib/services/general";

const ReviewSlider = dynamic(() => import("./ReviewSlider"), { ssr: false });
const Stars = dynamic(() => import("@/components/common/Stars"), {
  ssr: false,
});

const ReviewSection = () => {
  const { t } = useTranslation(["home"]);
  const [fetching, setFetching] = useState(true);
  const reviews = useRef({ items: [], average: "", total: 0 });

  useEffect(() => {
    const fetchCustomerReviews = async () => {
      try {
        const res = await getStoreReviews();
        reviews.current.total = res?.review_count || 0;
        reviews.current.average = res?.average_rating || "";
        reviews.current.items = res?.reviews?.map((r) => {
          return {
            time: r?.time_ago,
            rating: r?.rating,
            comment: r?.comments,
            user: r?.author?.name,
            verified: r?.verified === 1,
            address: r?.author?.location,
          };
        });
      } catch (err) {
        console.error(err);
      }
      setFetching(false);
    };
    fetchCustomerReviews();
    return () => {
      reviews.current = { items: [], average: "", total: 0 };
    };
  }, []);

  if (fetching) {
    return (
      <section className="bg-secondary-500 lg:py-[80px] h-[400px] xs:h-[625px] xs:py-11 pt-9 pb-24 comments-section animation-fadeIn"></section>
    );
  }

  return (
    <section className="bg-secondary-500 lg:py-[80px] xs:py-11 pt-9 pb-24 comments-section animation-fadeIn xs:min-h-max min-h-[400px]">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="bg-secondary-500">
              <div className="flex flex-col items-center justify-center">
                <h3 className={`${styles.heading32B2} xs:mb-3 mb-[2px]`}>
                  {t("reviews.heading")}
                </h3>
                <div className="review-sub-heading">
                  <span className="lg:text-body-text-1-xb xs:text-body-text-2-b text-minor-text-1-b">
                    {t("reviews.text.rating")}
                  </span>{" "}
                  <Stars
                    rating={+reviews.current.average}
                    prefix={t("reviews.heading")}
                  />
                  <span className="font-bold">{reviews.current.average}</span>{" "}
                  {t("reviews.text.based_on")}{" "}
                  <span className="font-bold">
                    {reviews.current.total?.toLocaleString()}
                  </span>{" "}
                  {t("reviews.text.reviews")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReviewSlider reviews={reviews.current.items} className="mb-0" />
    </section>
  );
};

export default ReviewSection;
